<template>
    <div class="ml-10 mr-10">
        <TabNav
            v-if="this.$route.params.uuid !== 'new'"
            :elements="[
                {text: this.$t('accounts.edit'),to: '/accounts/' + this.$route.params.uuid,active: true,},
                {text: this.$t('menu.transactions'),to: '/accounts/' + this.$route.params.uuid + '/transactions'}
            ]"
        ></TabNav>

        <TitleBar
            v-if="this.$route.params.uuid === 'new'"
            :disabled="loading"
            :loading="loading"
            :text="this.$t('accounts.new')"
        ></TitleBar>
        <TitleBar
            v-else
            :disabled="loading"
            :loading="loading"
            :menu="[{text: this.$t('accounts.delete'), callback: deleteElement, class: 'red--text'}]"
            :text="this.$t('accounts.edit')"
        ></TitleBar>

        <Tags v-if="this.$route.params.uuid !== 'new'" :element="$route.params.uuid"></Tags>

        <v-form @submit.prevent="formSubmit">

            <v-row>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="name"
                        :disabled="loading"
                        :error-messages="nameErrors"
                        :label="$t('accounts.name')"
                        outlined
                        @blur="$v.name.$touch()"
                        @input="$v.name.$touch()"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="bank"
                        :disabled="loading"
                        :error-messages="bankErrors"
                        :label="$t('accounts.bank')"
                        outlined
                        @blur="$v.bank.$touch()"
                        @input="$v.bank.$touch()"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-select
                        v-model="currency"
                        :disabled="loading"
                        :error-messages="currencyErrors"
                        :items="currencies"
                        :label="$t('accounts.currency')"
                        outlined
                        @blur="$v.currency.$touch()"
                        @input="$v.currency.$touch()"
                    ></v-select>
                </v-col>
            </v-row>

            <v-btn
                :loading="loading"
                color="secondary"
                elevation="2"
                type="submit"
            >{{ $route.params.uuid === "new" ? $t("accounts.add") : $t("accounts.update") }}
            </v-btn>
        </v-form>


        <!--        Fields -->

        <Attachments v-if="this.$route.params.uuid !== 'new'" :element="$route.params.uuid"></Attachments>

        <!--        <v-btn-->
        <!--            elevation="2"-->
        <!--            color="error"-->
        <!--            :loading="loading"-->
        <!--            @click.prevent="deleteElement"-->
        <!--            class="mt-10"-->
        <!--            v-if="this.$route.params.uuid !== 'new'"-->
        <!--        >{{ $t("accounts.delete") }}-->
        <!--        </v-btn>-->

    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Accounting from "../../helpers/Accounting";
import Tags from "../../components/Tags";
import Attachments from "../../components/Attachments";
import Currencies from "../../helpers/Currencies";
import Utils from "../../helpers/Utils";
import TitleBar from "@/components/TitleBar.vue";
import TabNav from "@/components/TabNav.vue";

export default {
    name: "Details",
    components: {TabNav, TitleBar, Attachments, Tags},
    mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.accounts"),
                to: "/accounts",
                exact: true
            },
            {
                text: this.$route.params.uuid === "new" ? this.$t("accounts.new") : this.$t("accounts.edit"),
                to: "/accounts/" + this.$route.params.uuid,
                exact: false
            }
        ]);

        if (this.$route.params.uuid !== "new") {
            this.loading = true;
            Accounting.get("/accounts/" + this.$route.params.uuid)
                .then(response => {
                    const data = response.data;
                    this.name = data.name;
                    this.currency = data.currency;
                    this.bank = data.bank_name;
                    this.loading = false;
                }).catch(error => {
                if (error.response) {
                    let errors = [];
                    for (const errorElement of error.response.data.errors) {
                        errors.push({
                            type: "error",
                            text: errorElement
                        });
                    }
                    this.$store.commit("SET_ALERTS", errors);
                } else if (error.request) {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: this.$t("api.no_response")
                    }]);
                } else {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: error.message
                    }]);
                }
                this.loading = false;
            });
        }
    },
    data: () => {
        return {
            loading: false,
            name: null,
            currency: null,
            bank: null,
            currencies: Currencies.get(),
            tab: 0
        };
    },
    watch: {
        tab(newValue) {
            if (newValue) {
                this.$router.push("/accounts/" + this.$route.params.uuid + "/transactions");
            }
        }
    },
    methods: {
        deleteElement() {
            this.$swal({
                title: this.$t("accounts.delete_title"),
                text: this.$t("accounts.delete_text"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.$t("accounts.delete_yes"),
                cancelButtonText: this.$t("accounts.delete_no"),
                confirmButtonColor: Utils.getColor(this, "error"),
                cancelButtonColor: Utils.getColor(this, "primary")
            }).then(result => {
                if (result.isConfirmed) {
                    this.loading = true;
                    this.$store.commit("SET_ALERTS", []);
                    Accounting.delete("/accounts/" + this.$route.params.uuid)
                        .then(() => {
                            this.$store.commit("SET_ALERTS", [{
                                type: "success",
                                text: this.$t("accounts.deleted")
                            }]);
                            this.loading = false;
                            this.$router.push("/accounts");
                        })
                        .catch(error => {
                            if (error.response) {
                                let errors = [];
                                for (const errorElement of error.response.data.errors) {
                                    errors.push({
                                        type: "error",
                                        text: errorElement
                                    });
                                }
                                this.$store.commit("SET_ALERTS", errors);
                            } else if (error.request) {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: this.$t("api.no_response")
                                }]);
                            } else {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: error.message
                                }]);
                            }
                            this.loading = false;
                        });
                }
            });
        },
        formSubmit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loading = true;

                let url = "/accounts";

                if (this.$route.params.uuid !== "new") {
                    url = url + "/" + this.$route.params.uuid;
                }

                this.$store.commit("SET_ALERTS", []);

                Accounting.post(url, {
                    currency: this.currency,
                    name: this.name,
                    bank: this.bank
                }).then(response => {

                    this.loading = false;
                    if (this.$route.params.uuid === "new") {
                        this.$store.commit("SET_ALERTS", [{
                            type: "success",
                            text: this.$t("accounts.added")
                        }]);
                        this.$router.push("/accounts/" + response.data.uuid);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "success",
                            text: this.$t("accounts.updated")
                        }]);
                    }

                }).catch(error => {
                    if (error.response) {
                        let errors = [];
                        for (const errorElement of error.response.data.errors) {
                            errors.push({
                                type: "error",
                                text: errorElement
                            });
                        }
                        this.$store.commit("SET_ALERTS", errors);
                    } else if (error.request) {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: this.$t("api.no_response")
                        }]);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: error.message
                        }]);
                    }
                    this.loading = false;
                });

            }
        }
    },
    mixins: [validationMixin],
    validations: {
        name: {required},
        bank: {required},
        currency: {required}
    },
    computed: {
        nameErrors() {
            const errors = [];
            if (!this.$v.name.$dirty) return errors;
            !this.$v.name.required && errors.push(this.$t("accounts.name_required"));
            return errors;
        },
        bankErrors() {
            const errors = [];
            if (!this.$v.name.$dirty) return errors;
            !this.$v.name.required && errors.push(this.$t("accounts.bank_required"));
            return errors;
        },
        currencyErrors() {
            const errors = [];
            if (!this.$v.currency.$dirty) return errors;
            !this.$v.currency.required && errors.push(this.$t("accounts.currency_required"));
            return errors;
        }
    }
};
</script>

<style>

</style>