export default {
    getColor(inst, color) {
        if (inst.$vuetify.theme.dark) {
            return inst.$vuetify.theme.themes.dark[color];
        }
        return inst.$vuetify.theme.themes.light[color];
    },

    parseAmount(a) {
        return parseFloat(a);
    },

    formatMoney(amount, currency, lang) {
        if (currency === null || currency === undefined) {
            currency = "GBP";
        }

        const formatter = new Intl.NumberFormat(lang, {
            style: "currency",
            currency: currency
        });

        return formatter.format(amount);
    }
}
;