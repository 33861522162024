<template>
    <div class="mt-2 mb-5">
        <v-progress-linear
            v-if="loading"
            color="secondary"
            indeterminate
        ></v-progress-linear>

        <v-toolbar class="mt-0" elevation="0">
            <v-toolbar-title>
                <slot>{{ text }}</slot>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-menu
                v-if="menu !== undefined && menu !== null && menu.length > 0"
                offset-y
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        :disabled="disabled"
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item
                        v-for="(item, index) in menu"
                        :key="index"
                        link
                        @click="item.callback"
                    >
                        <v-list-item-title :class="item.class">{{
                                item.text
                            }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-toolbar>
    </div>
</template>

<script>
export default {
    name: "TitleBar",
    props: ["text", "menu", "disabled", "loading"]
};
</script>

<style scoped>

</style>